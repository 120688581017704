'use client'

import { useEffect, useState } from 'react'

import type React from 'react'
import Script from 'next/script'
import { useInView } from 'react-intersection-observer'

interface ReCaptchaWrapperProps {
  children: React.ReactNode
}

const ReCaptchaWrapper: React.FC<ReCaptchaWrapperProps> = ({ children }) => {
  const [hasWindow, setHasWindow] = useState(false)
  const { ref, inView } = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0,
    rootMargin: '100px',
  })

  // During hydration `useEffect` is called. `window` is available in `useEffect`. In this case because we know we're in the browser checking for window is not needed. If you need to read something from window that is fine.
  // By calling `setHasWindow` in `useEffect` a render is triggered after hydrating, this causes the "browser specific" value to be available.
  useEffect(() => {
    setHasWindow(true)
  }, [])

  return (
    <div ref={ref}>
      {hasWindow && inView && (
        <Script
          id='recaptcha'
          async
          src={`https://www.google.com/recaptcha/api.js?render=${
            process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string
          }`}
        />
      )}
      {children}
    </div>
  )
}

export default ReCaptchaWrapper
