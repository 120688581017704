import { useFieldApi } from '@data-driven-forms/react-form-renderer'
import { type TextareaHTMLAttributes, type DetailedHTMLProps } from 'react'

const TextArea: React.FC<
  DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >
> = (props: any) => {
  const {
    customProp,
    label,
    input,
    isRequired,
    meta: { error, touched },
    FieldArrayProvider,
    dataType,
    ...rest
  } = useFieldApi(props)
  return (
    <textarea
      id={props.name}
      placeholder={props.label}
      {...props}
      {...input}
      {...rest}
    />
  )
}

export default TextArea
