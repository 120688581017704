import {
  componentTypes,
  useFormApi,
  validatorTypes,
} from '@data-driven-forms/react-form-renderer'
import { use, useEffect, useMemo, useState } from 'react'

import DataCheckbox from '@components/interaction/data-checkbox'
import DataFormSelectField from '@components/interaction/data-select'
/* eslint-disable @typescript-eslint/no-explicit-any */
import DataFormTextField from '@components/interaction/data-text-field'
import TextArea from '@components/interaction/text-area'

export const TwoColumns = ({ fields }: any) => {
  const { renderForm } = useFormApi()

  return (
    <div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
      {fields.map((field: any) => (
        <div key={field.name}>{renderForm([field])}</div>
      ))}
    </div>
  )
}
export const componentMapper = {
  [componentTypes.TEXT_FIELD]: DataFormTextField,
  [componentTypes.SELECT]: DataFormSelectField,
  [componentTypes.TEXTAREA]: TextArea,
  [componentTypes.CHECKBOX]: DataCheckbox,
  'two-columns': TwoColumns,
}

export const createUtmField = (name: string, value: string) => ({
  name,
  component: componentTypes.TEXT_FIELD,
  initialValue: value,
  hideField: true,
})

export const snakeToCamel = (str: string) =>
  str.replaceAll(/_./g, (x) => x.toUpperCase()[1])

const utmFields = [
  {
    name: 'utmSource',
    component: componentTypes.TEXT_FIELD,
    initialValue: '',
    hideField: true,
  },
  {
    name: 'utmMedium',
    component: componentTypes.TEXT_FIELD,
    initialValue: '',
    hideField: true,
  },
  {
    name: 'utmCampaign',
    component: componentTypes.TEXT_FIELD,
    initialValue: '',
    hideField: true,
  },
  {
    name: 'utmTerm',
    component: componentTypes.TEXT_FIELD,
    initialValue: '',
    hideField: true,
  },
]

const subscribeCheckboxField = {
  name: 'subscribe',
  label:
    'I would like to receive marketing communications regarding V2 insights, services and events.',
  component: componentTypes.CHECKBOX,
  initialValue: true,
}

const firstNameLastNameEmailFields = [
  {
    name: 'firstName',
    label: 'First name *',
    component: componentTypes.TEXT_FIELD,
    validate: [
      {
        type: validatorTypes.REQUIRED,
      },
      {
        type: validatorTypes.PATTERN,
        pattern: '[a-zA-Z]{2,}',
        message:
          'First name must be longer than 2 characters and contain only (a to z).',
      },
    ],
  },
  {
    name: 'lastName',
    label: 'Last name *',
    component: componentTypes.TEXT_FIELD,
    validate: [
      {
        type: validatorTypes.REQUIRED,
      },
      {
        type: validatorTypes.PATTERN,
        pattern: "[a-zA-Z' -]{2,}",
        message:
          "Last name must be longer than 2 characters and contain only (a to z) or (- ').",
      },
    ],
  },
  {
    name: 'email',
    label: 'Email address *',
    component: componentTypes.TEXT_FIELD,
    inputMode: 'email',
    validate: [
      {
        type: validatorTypes.REQUIRED,
      },
      {
        type: validatorTypes.PATTERN,
        pattern: '[a-zA-Z0-9\\.-_]+@[a-zA-Z0-9\\.-_]+\\.[a-zA-Z]+',
        message: 'Email address must be valid e.g. john@company.com',
      },
    ],
  },
]

const jobTitleField = {
  name: 'jobTitle',
  label: 'Job title *',
  component: componentTypes.TEXT_FIELD,
  validate: [
    {
      type: validatorTypes.REQUIRED,
    },
    {
      type: validatorTypes.PATTERN,
      pattern: '[a-zA-Z]{2}.*',
      message: 'Please enter you job title',
    },
  ],
}

const companyNameField = {
  name: 'companyName',
  label: 'Company name',
  component: componentTypes.TEXT_FIELD,
}

const countrySelectField = {
  name: 'country',
  label: 'Country',
  component: componentTypes.SELECT,
  options: [
    { label: 'Australia', value: 'Australia' },
    { label: 'Malaysia', value: 'Malaysia' },
    { label: 'Philippines', value: 'Philippines' },
    { label: 'Singapore', value: 'Singapore' },
    { label: 'New Zealand', value: 'New Zealand' },
    { label: 'Other', value: 'Other' },
  ],
}

// Use by contact page
export const contactFormSchema = {
  fields: [
    {
      name: 'layout',
      // two columns layout for desktop, a single column for mobile(by flattening two-columns fields)
      // refer to TwoColumns for more details
      component: 'two-columns',
      fields: [
        ...firstNameLastNameEmailFields,
        {
          name: 'phone',
          label: 'Phone number',
          component: componentTypes.TEXT_FIELD,
          inputMode: 'numeric',
          validate: [
            {
              type: validatorTypes.PATTERN,
              pattern: '^\\+?[0-9]+[-0-9]*$',
              message: 'Numbers, +, -, (, ) only',
            },
          ],
        },
        jobTitleField,
        companyNameField,
        countrySelectField,
        {
          name: 'industry',
          label: 'Industry',
          component: componentTypes.SELECT,
          options: [
            { label: 'Agriculture', value: 'Agriculture' },
            { label: 'Banking & Finance', value: 'Banking & Finance' },
            {
              label: 'Construction & Engineering',
              value: 'Construction & Engineering',
            },
            { label: 'Education', value: 'Education' },
            { label: 'Federal Government', value: 'Federal Government' },
            { label: 'Healthcare', value: 'Healthcare' },
            { label: 'Insurance', value: 'Insurance' },
            { label: 'Local Government', value: 'Local Government' },
            { label: 'Manufacturing', value: 'Manufacturing' },
            { label: 'Media & Entertainment', value: 'Media & Entertainment' },
            { label: 'Mining', value: 'Mining' },
            { label: 'Not For Profit', value: 'Not For Profit' },
            { label: 'Other', value: 'Other' },
            { label: 'Professional Services', value: 'Professional Services' },
            { label: 'Retail', value: 'Retail' },
            { label: 'State Government', value: 'State Government' },
            { label: 'Technology', value: 'Technology' },
            { label: 'Telecommunications', value: 'Telecommunications' },
            { label: 'Transportation', value: 'Transportation' },
            { label: 'Utilities', value: 'Utilities' },
          ],
        },
      ],
    },

    // message text area and subscribe checkbox
    {
      name: 'message',
      label: 'Message',
      component: componentTypes.TEXTAREA,
      className:
        'col-span-1 my-3 w-full border border-black border-opacity-50 bg-white px-6 py-5 text-field font-light text-black',
      rows: 5,
    },

    subscribeCheckboxField,

    // hidden utm fields
    ...utmFields,
  ],
}

// Use by home and insight page
export const signUpSchema = {
  fields: [
    {
      name: 'layout',
      // two columns layout for desktop, a single column for mobile(by flattening two-columns fields)
      // refer to TwoColumns for more details
      component: 'two-columns',
      fields: [
        ...firstNameLastNameEmailFields,
        companyNameField,
        jobTitleField,
        countrySelectField,
      ],
    },
    subscribeCheckboxField,

    // hidden utm fields
    ...utmFields,
  ],
}

export const useUtmFields = () => {
  const [utmFields, setUtmFields] = useState([] as any)
  useEffect(() => {
    // Get UTM parameters
    const dhmUrlParams836 = new URLSearchParams(window.location.search)
    const dhmUtms836 = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      // 'dc',
      // 'utm_content',
    ]

    const fields = []
    for (const element of dhmUtms836) {
      const utmElFromUrl = dhmUrlParams836.get(element)
      // utm fields get lost in URL because visitor jumped a few pages within our site
      // when it's null, try to retrieve it from local storage
      if (utmElFromUrl == null) {
        const utmElFromLocal = localStorage.getItem('dhm836_' + element)
        utmElFromLocal != null && fields.push({ [element]: utmElFromLocal })
      }
      // direct visit with utm in the URL
      else {
        localStorage.setItem('dhm836_' + element, utmElFromUrl)
        fields.push({ [element]: utmElFromUrl })
      }
    }

    // save the UTMs
    setUtmFields(fields)
  }, [])
  return utmFields
}

export const useUtmInitialValues = () => {
  const utmFields = useUtmFields()
  return useMemo(() => {
    const initialValues: any = {}
    utmFields.forEach((field: Record<string, string>) => {
      const key = Object.keys(field)[0]
      const camelKey = snakeToCamel(key)
      initialValues[camelKey] = field[key]
    })
    return initialValues
  }, [utmFields])
}
