import { useFieldApi } from '@data-driven-forms/react-form-renderer'
import { type InputHTMLAttributes } from 'react'

const DataFormTextField: React.FC<InputHTMLAttributes<HTMLInputElement>> = (
  props: any,
) => {
  const {
    customProp,
    label,
    input,
    isRequired,
    meta: { error, touched },
    FieldArrayProvider,
    dataType,
    ...rest
  } = useFieldApi(props)

  return (
    <>
      <input
        className={`my-3 w-full border border-black border-opacity-50 bg-white px-6 py-5 text-field font-light text-black`}
        id={props.name}
        placeholder={label}
        required={isRequired}
        {...input}
        {...rest}
      />
      {touched && Boolean(error) && (
        <p className='ml-1 mt-1 text-red-500'>{error}</p>
      )}
    </>
  )
}

export default DataFormTextField
