import { useFieldApi } from '@data-driven-forms/react-form-renderer'
import { type InputHTMLAttributes } from 'react'

const DataFormSelectField: React.FC<InputHTMLAttributes<HTMLInputElement>> = (
  props: any,
) => {
  const {
    customProp,
    label,
    input,
    isRequired,
    meta: { error, touched },
    FieldArrayProvider,
    dataType,
    options,
    ...rest
  } = useFieldApi(props)

  return (
    <div className='my-3 border border-black border-opacity-50'>
      <select
        id='industry'
        defaultValue={undefined}
        className='w-[95%] border-0 bg-white px-6 py-5 text-field font-light'
        {...input}
        {...rest}
      >
        <option value={undefined}>{label}</option>
        {options.map((option: { label: string; value: string }) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default DataFormSelectField
