'use client'

import { useFieldApi } from '@data-driven-forms/react-form-renderer'
import styles from './toggle-button.module.css'
import { useState } from 'react'

interface ToggleButtonProps {
  label: string
  id?: string
  name: string
  defaultChecked?: boolean
  onChange?: (value: boolean) => void
  borderGrey?: boolean
}

const DataCheckbox: React.FC<ToggleButtonProps> = (props: any) => {
  const {
    customProp,
    label,
    input,
    isRequired,
    meta: { error, touched },
    FieldArrayProvider,
    dataType,
    ...rest
  } = useFieldApi(props)

  return (
    <div className='my-5 flex items-center'>
      <div className='relative mr-3 flex w-max cursor-pointer select-none items-center'>
        <input
          type='checkbox'
          className={`${styles['toggle-checkbox']} h-11 w-20 cursor-pointer appearance-none rounded-full transition-colors focus:outline-none `}
          id={props.name}
          placeholder={label}
          required={isRequired}
          {...input}
          {...rest}
        />
        <span
          className={`${styles['toggle-dot']} absolute h-10 w-10 transform rounded-full bg-white transition-transform`}
        />
      </div>
      <label className='text-body2 sm:text-body1'>{label}</label>
    </div>
  )
}

export default DataCheckbox
